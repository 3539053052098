<template>
  <div class="flex-1 overflow-hidden" style="width: 100%">
    <component
      v-if="this.contentTemplate !== ''"
      :is="{
        name: 'dynamic-content',
        template: this.contentTemplate,
      }"
    />
  </div>
</template>

<script>
import template from "../data/threed.json";
export default {
  data() {
    return {
      contentTemplate: template.template.content,
      // contentTemplate: "",
    };
  },
    metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Loviisan Aitta 3D myymälä",
        },
      ],
    };
  },
  created: function () {
    // this.BBShopApi("threed").then((res) => {
    //   this.contentTemplate = res.data.template.content;
    //   if (res.data.template.data) {
    //     this.$store.commit("update3DStoreData", res.data.template.data.d3Store);
    //   }
    // });
    if (template.template.data.d3Store) {
      if (this.$store.state.cms.D3StoreData.length < 1) {
        this.$store.commit("update3DStoreData", template.template.data.d3Store);
      }
    }
  },
};
</script>

<style>
</style>